import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { FeedbackService } from '../services/feedback.service';
import { Comment } from '../shared/models';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Constants } from "../shared/constants";
import { ListeningPostSettings } from '../listening-post-settings';

@Component({
  selector: 'pl-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  private paramsRedirect: string;
  private paramsAppName: string;
  private paramsAppOs: string;
  private paramsAppVersion: string;
  private paramsAppDevice: string;
  private paramsPremeraId: string;

  clicked: boolean;
  listensForm: FormGroup;
  lob: string;
  submitted = false;
  isHmo = false;
  isAlaska = false;
  isProvider = false;
  listeningPostTitle: string;
  listeningPostSubtitle: string;
  commentFieldLabel: string;

  constructor(
    private formBuilder: FormBuilder,
    private feedbackService: FeedbackService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.isProvider = this.isProviderSurveyType();
    this.buildForm();
    this.lob = ListeningPostSettings.lineOfBusiness;
    this.listeningPostTitle = this.isProvider
      ? `How was your recent provider experience working with ${this.lob} (customer service, credentialing, Availity tools, resources, etc.)?`
      : `How was your recent experience with ${this.lob}?`;
    this.listeningPostSubtitle = this.isProvider
      ? "We'd like to hear your feedback."
      : "We'd like to hear from you!";
    this.commentFieldLabel = this.isProvider
      ? ""
      : "Please tell us!";

    this.isHmo = ListeningPostSettings.brandingType === Constants.brandTypePremeraHMO;
    this.isAlaska = ListeningPostSettings.brandingType === Constants.brandTypePremeraAlaska;
    this.submitted = false;
    this.clicked = false;
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.readRouterParams(params);
      this.redirectToInvalidPage();
    });
  }

  async submitForm() {
    this.submitted = true;

    if (this.listensForm.invalid) return;

    this.clicked = true;
    const listeningPostSurvey = new Comment();
    if (this.isProvider) {
      listeningPostSurvey.providerOrganization = this.providerOrganizationControl?.value;
      listeningPostSurvey.providerSubmittedContactInfo = this.providerSubmittedContactInfoControl?.value;
      listeningPostSurvey.providerTin = this.providerTinControl?.value;
      listeningPostSurvey.providerSubmittedName = this.providerSubmittedNameControl?.value;
    } else {
      listeningPostSurvey.memberSubmittedName = this.memberSubmittedNameControl?.value;
      listeningPostSurvey.memberSubmittedContactInfo = this.memberSubmittedContactInfoControl?.value;
    }
    listeningPostSurvey.comment = this.commentControl?.value;
    listeningPostSurvey.businessUnit = this.lob;
    listeningPostSurvey.redirectSource = this.paramsRedirect;
    listeningPostSurvey.appName = this.paramsAppName;
    listeningPostSurvey.device = this.paramsAppDevice;
    listeningPostSurvey.operatingSystem = this.paramsAppOs;
    listeningPostSurvey.appVersion = this.paramsAppVersion;
    listeningPostSurvey.surveyType = ListeningPostSettings.surveyType;
    listeningPostSurvey.isHmo = this.isHmo;
    listeningPostSurvey.isAlaska = this.isAlaska;
    listeningPostSurvey.premeraId = this.paramsPremeraId;

    if (listeningPostSurvey.comment.length < 4) {
      this.goToThankYou();
      return;
    }

    this.feedbackService.addComment(listeningPostSurvey).subscribe({
      next: () => {
        this.goToThankYou();
      },
      error: () => {
        this.goToThankYou();
      }
    });
  }

  private goToThankYou(): void {
    this.router.navigate([`/${Constants.routeThankYou}`]);
  }

  get formRegister() {
    return this.listensForm.controls;
  }

  buildForm() {
    if (this.isProviderSurveyType())
      this.listensForm = this.formBuilder.group({
        providerSubmittedContactInfo: [''],
        providerOrganization: [''],
        providerTin: [''],
        comment: [''],
        providerSubmittedName: ['']
      });
    else
      this.listensForm = this.formBuilder.group({
        memberSubmittedName: [''],
        memberSubmittedContactInfo: [''],
        comment: ['']
      });
  }

  private redirectToInvalidPage() {
    const redirect = this.paramsRedirect?.toUpperCase();

    if (redirect !== "MOBILE" && this.paramsRedirect != null) {
      this.router.navigate([`/${Constants.routeInvalidRoute}`]);
      return;
    }
  }

  private readRouterParams(params: ParamMap): void {
    this.paramsRedirect = this.readRouteParamCaseInsensitive(params, "redirect");
    this.paramsAppName = this.readRouteParamCaseInsensitive(params, "name");
    this.paramsAppOs = this.readRouteParamCaseInsensitive(params, "os");
    this.paramsAppVersion = this.readRouteParamCaseInsensitive(params, "version");
    this.paramsAppDevice = this.readRouteParamCaseInsensitive(params, "device");
    this.paramsPremeraId = this.readRouteParamCaseInsensitive(params, "premeraid");
  }

  private readRouteParamCaseInsensitive(params: ParamMap, name: string): string {
    let key = params?.keys?.find(x => x?.toLowerCase() == name.toLowerCase())
    return key ? params?.get(key) : null;
  }

  private isProviderSurveyType(): boolean {
    return ListeningPostSettings.surveyType === Constants.surveyTypeListeningPostProvider
      || ListeningPostSettings.surveyType === Constants.surveyTypeListeningPostProviderGroup
      || ListeningPostSettings.surveyType === Constants.surveyTypeListeningPostProviderStudent;
  }

  private get providerOrganizationControl(): AbstractControl {
    return this.listensForm.controls["providerOrganization"];
  }

  private get providerSubmittedContactInfoControl(): AbstractControl {
    return this.listensForm.controls["providerSubmittedContactInfo"];
  }

  private get providerTinControl(): AbstractControl {
    return this.listensForm.controls["providerTin"];
  }

  private get providerSubmittedNameControl(): AbstractControl {
    return this.listensForm.controls["providerSubmittedName"];
  }

  private get memberSubmittedNameControl(): AbstractControl {
    return this.listensForm.controls["memberSubmittedName"];
  }

  private get memberSubmittedContactInfoControl(): AbstractControl {
    return this.listensForm.controls["memberSubmittedContactInfo"];
  }

  private get commentControl(): AbstractControl {
    return this.listensForm.controls["comment"];
  }

}
