import { Component, OnInit } from '@angular/core';
import { FooterSettings, SupportedLanguage } from '../shared/models';
import { Constants } from "../shared/constants";
import { ListeningPostSettings } from '../listening-post-settings';
import { ListeningPostFooterContentBase } from './settings/listening-post-footer-content-base';
import { PremeraBlueCrossHmoFooterContent } from './settings/premera-blue-cross-hmo-footer-content';
import { LifeWiseAssuranceFooterContent } from './settings/lifewise-assurance-footer-content';
import { LifeWiseIndividualFooterContent } from './settings/lifewise-individual-footer-content';
import { LifeWiseIndividualGroupFooterContent } from './settings/lifewise-individual-group-footer-content';
import { PremeraBlueCrossFooterContent } from './settings/premera-blue-cross-footer-content';
import { PremeraBlueCrossAlaskaFooterContent } from './settings/premera-blue-cross-alaska-footer-content';

@Component({
  selector: 'pl-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  lob: string;
  copyrightText: string;
  footerSettings: FooterSettings;

  ngOnInit() {
    const footerContent = this.getFooterContent();
    this.footerSettings = footerContent.GetFooterSettings();
    this.lob = ListeningPostSettings.lineOfBusiness;
    this.copyrightText = `&copy; ${new Date().getFullYear()}<br>${this.footerSettings.legalEntityName}`;
  }

  private getFooterContent(): ListeningPostFooterContentBase {
    if (ListeningPostSettings.brandingType === Constants.brandTypePremeraHMO)
      return new PremeraBlueCrossHmoFooterContent();

    if (ListeningPostSettings.brandingType === Constants.brandTypePremeraAlaska)
      return new PremeraBlueCrossAlaskaFooterContent();

    if (ListeningPostSettings.brandingType === Constants.brandTypeLifeWiseAssuranceCompany)
      return new LifeWiseAssuranceFooterContent();

    if (ListeningPostSettings.surveyType === Constants.surveyTypeListeningPostIndividual
      || (ListeningPostSettings.surveyType === Constants.surveyTypeListeningPostProvider && ListeningPostSettings.brandingType === Constants.brandTypeLifeWiseOfWashington)
      || (ListeningPostSettings.surveyType === Constants.surveyTypeListeningPostMobile && ListeningPostSettings.brandingType === Constants.brandTypeLifeWiseOfWashington))
      return new LifeWiseIndividualFooterContent();

    if (ListeningPostSettings.brandingType === Constants.brandTypeLifeWiseOfWashington)
      return new LifeWiseIndividualGroupFooterContent();

    return new PremeraBlueCrossFooterContent();
  }

}
